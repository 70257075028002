.checkbox {
  display: flex;
  align-items: center;
  user-select: none;

  & > * {
    cursor: pointer;
  }

  .form-check-input {
    height: 25px;
    width: 25px;
    min-width: 25px;
    min-height: 25px;
    position: relative;

    &:focus {
      box-shadow: none;
    }
  }

  .form-check-label {
    line-height: initial;
    height: min-content;
    font-weight: 500;
    color: $darker-gray;
    padding-top: 3px;
    padding-left: 10px;
    width: 100%;
    text-align: left;
  }

  &--big {
    .form-check-input {
      min-height: 31px;
      min-width: 31px;
      height: 31px;
      width: 31px;
    }
  }

  &--small {
    .form-check-input {
      min-height: 21px;
      min-width: 21px;
      height: 21px;
      width: 21px;
    }
  }

  &__description {
    margin-left: 35px;
  }
}

;@import "sass-embedded-legacy-load-done:122";